import { useState } from 'react'
import style from './CopyData.module.css'

export const CopyData = ({ children }) => {
    const copyClasses = style.copy_data
        + (children === 'не указан' ? ' ' + style.copy_data_no_copy : '')

    const [copied, setCopied] = useState(false)
    const handleCopy = () => {
        navigator.clipboard.writeText(children)
        setCopied(true)
    }

    return (
        <span
            onClick={handleCopy} className={copyClasses}
            customtip={copied ? 'Скопировано!' : 'Скопировать'}
            onMouseLeave={() => setCopied(false)}
        >
            {children}
        </span>
    )
}
