export const UploadIcon = () => {
    return (
        <svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='50' height='50' rx='25' />
            <path
                d='M26.1716 16H22C20.1144 16 19.1716 16 18.5858 16.5858C18 17.1716 18 18.1144 18 20V30C18 31.8856 18 32.8284 18.5858 33.4142C19.1716 34 20.1144 34 22 34H28C29.8856 34 30.8284 34 31.4142 33.4142C32 32.8284 32 31.8856 32 30V21.8284C32 21.4197 32 21.2153 31.9239 21.0315C31.8478 20.8478 31.7032 20.7032 31.4142 20.4142L27.5858 16.5858C27.2968 16.2968 27.1522 16.1522 26.9685 16.0761C26.7847 16 26.5803 16 26.1716 16Z'
                strokeWidth='2'
            />
            <path
                d='M26 16V20C26 20.9428 26 21.4142 26.2929 21.7071C26.5858 22 27.0572 22 28 22H32'
                strokeWidth='2'
            />
        </svg>

    )
}
