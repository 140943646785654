import style from './Input.module.css'

export const Input = ({ required, error, textarea, ...props }) => {
    const inputClasses = style.input
        + (textarea ? ' ' + style.input_textarea : '')
        + (error ? ' ' + style.input_error : '')
    
    if (textarea) {
        return (
            <div className={inputClasses}>
                <textarea {...props} />
            </div>
        )
    }
    return (
        <div className={inputClasses}>
            <input {...props} />
        </div>
    )
}
