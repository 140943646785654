import { useEffect, useState } from 'react'
import termsFile from 'assets/files/offer.pdf'
import { Block, Button, Checkbox, CustomLink, Fieldset, FlexColumn, Form, FormGrid, Input, Label, PhoneInput, Select, Title } from 'common/components'
import { clubs, universities, emailREGEX } from 'common/data'
import { leadApi } from 'common/http'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { validatePhone } from 'common/utils'
import { FileInput } from '../components/FileInput/FileInput'
import { vkAnalitycs, yandexMetrics } from 'common/analytics'

const MAX_FILES_COUNT = 3
const MAX_FILE_SIZE = 8388608

export const ModalForm = ({ onClose, onSuccess }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const [files, setFiles] = useState([])
    const [filesError, setFilesError] = useState(false)

    useEffect(() => {
        checkFileErrors()
    }, [files]) // eslint-disable-line

    const sortedUniversities = universities.sort((a, b) => a.name.localeCompare(b.name))
    const sortedClubs = clubs.sort((a, b) => a.name.localeCompare(b.name))

    const checkFiles = (e) => {
        if (files?.length >= MAX_FILES_COUNT) {
            e.preventDefault()
            return
        }
    }

    const handleFileUpload = (e) => {
        if (files?.length >= MAX_FILES_COUNT) return
        if (!e.target.files[0]) return
        let uploaded = e.target.files[0]
        uploaded.id = Date.now() + 123

        if (uploaded['size'] > MAX_FILE_SIZE) {
            uploaded.sizeError = true
            setFilesError(true)
        }

        setFiles(prev => [...prev, uploaded])
    }

    const removeUploadingFile = (id) => {
        setFiles(files.filter(file => file.id !== id))
    }

    const checkFileErrors = () => {
        let errors = 0
        for (let i = 0; i < files.length; i++) {
            if (files[i].sizeError === true) {
                errors += 1
                setFilesError(true)
            }
        }
        if (errors === 0) {
            setFilesError(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            age: '',
            phone: '',
            email: '',
            club: '',
            // photoSession: '',
            university: '',
            comment: '',
            personalData: true,
            offerTerms: true
        },
        validationSchema: Yup.object({
            name: Yup.string().trim().required().min(1).max(100),
            age: Yup.number().required('age required').min(18, 'invalid age').max(100, 'invalid age'),
            phone: Yup.string().required().test('invalid phone', 'invalid phone', validatePhone),
            email: Yup.string().matches(emailREGEX).max(50),
            club: Yup.mixed().test('club', 'club', (c) => c?.name),
            // university: Yup.mixed().test('university', 'university', (u) => u?.name),
            comment: Yup.string().trim().max(255, 'too long comment'),
            personalData: Yup.bool().test('invalid value', 'invalid value', val => val),
            offerTerms: Yup.bool().test('invalid value', 'invalid value', val => val),
        }),
        onSubmit: async (values, actions) => {
            setError('')
            setLoading(true)

            const data = new FormData()
            data.append('name', values.name.trim())
            data.append('age', values.age)
            data.append('phone', values.phone.replace(/[^\d]/g, ''))
            data.append('email', values.email.trim())
            data.append('club', values.club.name || '')
            data.append('university', values.university.name || '')
            data.append('comment', values.comment.trim())
            data.append('personalData', values.personalData)
            data.append('offerTerms', values.offerTerms)
            files.forEach((file, idx) => data.append(`file_${idx + 1}`, file))

            try {
                const dataResponse = await leadApi.postLead(data)

                if (dataResponse.status === 400) {
                    setError(dataResponse.data.message)
                    setLoading(false)
                    actions.resetForm()
                    // очистка файлового инпута
                    document.getElementById('file').value = ''
                    setFiles([])
                    return
                }

                if (dataResponse.status !== 200) {
                    setError('При отправке данных произошла ошибка')
                    setLoading(false)
                    actions.resetForm()
                    setFiles([])
                    return
                }

                actions.resetForm()
                setFiles([])
                setLoading(false)

                // yandex, vk analytics
                yandexMetrics('lead')
                vkAnalitycs('lead')

                onSuccess()
            } catch (e) {
                setError('При отправке данных произошла ошибка')
            } finally {
                setLoading(false)
            }
        },
    })

    return (
        <Block large close={onClose}>
            <Title>Анкета</Title>

            <Form onSubmit={formik.handleSubmit}>
                <FlexColumn>
                    <FormGrid>

                        <Fieldset>
                            <Label required>ФИО</Label>
                            <Input
                                placeholder='Укажите Ваше ФИО'
                                error={formik.errors.name && formik.touched.name}
                                type='text' name='name' id='name'
                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                value={formik.values.name} disabled={loading}
                            />
                        </Fieldset>

                        <Fieldset>
                            <Label required>Возраст</Label>
                            <Input
                                onKeyDown={(e) => ((e.key.toLowerCase() === 'e') || (e.key === ',') || (e.key === '.')) && e.preventDefault()}
                                placeholder='Укажите Ваш возраст'
                                error={formik.errors.age && formik.touched.age}
                                type='number' name='age' id='age'
                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                value={formik.values.age} disabled={loading}
                            />
                        </Fieldset>

                        <Fieldset>
                            <Label required>Телефон</Label>
                            <PhoneInput
                                placeholder='Укажите Ваш телефон'
                                error={formik.errors.phone && formik.touched.phone}
                                type='text' name='phone' id='phone'
                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                value={formik.values.phone} disabled={loading}
                            />
                        </Fieldset>

                        <Fieldset>
                            <Label>E-mail</Label>
                            <Input
                                placeholder='Укажите Ваш E-mail'
                                error={formik.errors.email && formik.touched.email}
                                type='text' name='email' id='email'
                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                value={formik.values.email} disabled={loading}
                            />
                        </Fieldset>

                        <Fieldset>
                            <Label required>Ваш клуб</Label>
                            <Select
                                search
                                options={sortedClubs} placeholder='Укажите Ваш клуб'
                                error={formik.errors.club && formik.touched.club}
                                name='club' id='club'
                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                value={formik.values.club} disabled={loading}
                            />
                        </Fieldset>

                        <Fieldset>
                            <Label>Ваш ВУЗ</Label>
                            <Select
                                search
                                options={sortedUniversities} placeholder='Укажите Ваш ВУЗ'
                                error={formik.errors.university && formik.touched.university}
                                name='university' id='university'
                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                value={formik.values.university} disabled={loading}
                            />
                        </Fieldset>

                        <Fieldset wide>
                            <Label>Комментарии</Label>
                            <Input
                                textarea
                                type='text' placeholder='Оставьте комментарии...'
                                error={formik.errors.comment && formik.touched.comment}
                                name='comment' id='comment'
                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                value={formik.values.comment} disabled={loading}
                            />
                        </Fieldset>

                    </FormGrid>

                    <FileInput
                        name='file' id='file'
                        disabled={loading}
                        checkFiles={checkFiles}
                        handleFileUpload={handleFileUpload}
                        removeUploadingFile={removeUploadingFile}
                        files={files}
                    />

                    <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
                        <Checkbox
                            name='personalData' id='personalData' checked={formik.values.personalData}
                            onChange={formik.handleChange} disabled={loading}
                        >
                            Даю согласие на обработку <CustomLink newTab href='https://www.ddxfitness.ru/policy/'>персональных данных</CustomLink>
                        </Checkbox>
                        <Checkbox
                            name='offerTerms' id='offerTerms' checked={formik.values.offerTerms}
                            onChange={formik.handleChange} disabled={loading}
                        >
                            Cогласен с условиями <CustomLink newTab href={termsFile}>оферты</CustomLink>
                        </Checkbox>
                    </div>

                    <div>
                        <Button
                            type='submit'
                            disabled={Object.keys(formik.errors).length || loading || !files.length || filesError}
                        >
                            {loading ? 'Отправка данных' : 'Отправить заявку'}
                        </Button>
                    </div>
                </FlexColumn>

            </Form>

            {error && <p style={{ color: 'var(--orange)' }}>{error}</p>}
        </Block>
    )
}