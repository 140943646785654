import style from './Leads.module.css'

export const ScrollTop = ({ ...props }) => {
    return (
        <button {...props} className={style.scroll_top}>
            <svg viewBox='0 0 24 24'xmlns='http://www.w3.org/2000/svg'>
                <circle cx='12' cy='12' r='10'></circle>
                <path d='M15.21,9.79l-2.5-2.5a1,1,0,0,0-1.42,0l-2.5,2.5a1,1,0,0,0,1.42,1.42l.79-.8V16a1,1,0,0,0,2,0V10.41l.79.8a1,1,0,0,0,1.42,0A1,1,0,0,0,15.21,9.79Z'></path>
            </svg>
        </button>
    )
}