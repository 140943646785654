const PRODUCTION = true

// yandex metrics

export const yandexMetrics = (type) => {

    // lead - отправка лида
    // view_form - открытие формы
    // click_tariff - переход к тарифам

    if (!type) {
        return
    }

    PRODUCTION 
        ? window.ym(93257841, 'reachGoal', type)
        : console.log('ym - ', type)

}

// vk analytics

export const vkAnalitycs = (goal) => {

    // 'lead' - отправка лида
    // 'view_form' - открытие формы
    // 'click_tariff' - переход к тарифам
    // 'click_phone' - нажатие на номер телефона

    if (!goal) {
        return
    }

    PRODUCTION
        ? window._tmr.push({ type: 'reachGoal', id: 3325794, goal })
        : console.log('vka - ', goal)

}

// utm links

export const ddxTarrifsLink = PRODUCTION 
    ? 'https://www.ddxfitness.ru/tarrifs/?utm_source=website_big_city&utm_medium=referral&utm_campaign=big_city_2'
    : 'https://www.ddxfitness.ru/tarrifs'

export const ddxMainLink = PRODUCTION 
    ? 'https://www.ddxfitness.ru/?utm_source=website_big_city&utm_medium=referral&utm_campaign=big_city_2'
    : 'https://www.ddxfitness.ru'