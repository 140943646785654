import style from './Pagination.module.css'

export const PaginationItem = ({ selected, placeholder, children, ...props }) => {
    const itemClasses = style.pagination_item
        + (selected ? ' ' + style.pagination_item_selected : '')
        + (placeholder ? ' ' + style.pagination_item_placeholder : '')

    return (
        <div {...props} className={itemClasses}>
            {children}
        </div>
    )
}
