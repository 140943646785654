import style from './Button.module.css'

export const Button = ({ children, light, disabled, social, ...props }) => {
    const buttonClasses = style.btn 
        + (light ? ' ' + style.btn_light : '')
        + (disabled ? ' ' + style.btn_disabled : '')
        + (social ? ' ' + style.btn_social : '')

    return (
        <button {...props} disabled={disabled} className={buttonClasses}>
            {children}
        </button>
    )
}