import React, { createContext } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { App } from './App'
import { BrowserRouter } from 'react-router-dom'
import { AppStore, AuthStore } from 'store'
import { LeadStore } from 'store'

export const AppContext = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <AppContext.Provider value={{
			appStore: new AppStore(),
            authStore: new AuthStore(),
            leadStore: new LeadStore(),
        }}>
            <App />
        </AppContext.Provider>
    </BrowserRouter>
);
