import style from '../Table.module.css'

export const WatchedBtn = ({ watched, ...props }) => {
    const watchedClasses = style.watch_btn
        + (watched ? ' ' + style.watch_btn_watched : '')

    return (
        <div {...props} className={watchedClasses}>
            <svg height="32px" width="32px" version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 252.00 252.00" >
                <path strokeWidth='3' d="M204.795,83.441c-3.559-3.47-9.256-3.399-12.727,0.16l-64.073,65.706l-7.379-7.379l44.623-45.761 c3.47-3.559,3.399-9.256-0.16-12.727c-3.559-3.47-9.257-3.399-12.727,0.16L107.888,129.2l-5.32-5.32 c-3.515-3.515-9.213-3.515-12.728,0c-3.515,3.515-3.515,9.213,0,12.728l5.48,5.48l-7.04,7.22l-25.428-25.428 c-3.515-3.515-9.213-3.515-12.728,0c-3.515,3.515-3.515,9.213,0,12.728l31.873,31.873c1.688,1.688,3.978,2.636,6.364,2.636 c0.019,0,0.038,0,0.057,0c2.407-0.015,4.707-0.994,6.387-2.717l13.245-13.583l13.663,13.663c1.688,1.688,3.978,2.636,6.364,2.636 c0.019,0,0.038,0,0.057,0c2.407-0.015,4.707-0.994,6.387-2.717l70.436-72.231C208.426,92.609,208.354,86.911,204.795,83.441z"></path>
            </svg>
        </div>
    )
}
