import style from './Fieldset.module.css'

export const Fieldset = ({ children, wide }) => {
    const fieldsetClasses  = style.fieldset
        + (wide ? ' ' + style.fieldset_wide : '')
        
    return (
        <fieldset className={fieldsetClasses}>
            {children}
        </fieldset>
    )
}
