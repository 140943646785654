import React, { useEffect, useRef, useState } from 'react'
import style from './Select.module.css'
import { SelectArrow } from './SelectArrow';
import { SelectList } from './SelectList';

export const Select = ({ placeholder, search, small, options = [], value, onChange, onBlur, error, ...props }) => {
    const [isOpened, setIsOpened] = useState(false)
    const rootEl = useRef(null)
    const inputEl = useRef(null)

    const selectClasses = style.select
        + (isOpened ? ' ' + style.select_opened : '')
        + (small ? ' ' + style.select_small : '')
        + (error ? ' ' + style.select_error : '')

    // only for search option
    const [searchStr, setSearchStr] = useState('')
    const filteredOptions = search ? 
        options.filter(option =>
            option.name.toLowerCase().includes(searchStr.toLowerCase().trim()) 
        ) : options
    
    const isNotSearch = !search

    useEffect(() => {
        const onClick = e => rootEl.current.contains(e.target) || setIsOpened(false)
        if (isOpened) document.addEventListener('click', onClick)
        return () => { isOpened && document.removeEventListener('click', onClick) }
    }, [isOpened]);

    const handleSelect = (item) => {
        onChange({ target: { value: item, name: props.name, id: props.id } })
        toggleSelect(item)

        if (search) {
            setSearchStr('')
        }
    }

    const toggleSelect = (item) => {
        if (isOpened) {
            inputEl.current.blur()
        } else {
            onBlur({ target: { value: item || props.value, name: props.name, id: props.id } })
        }
        setIsOpened(prev => !prev)
    }

    const inputValue = isNotSearch 
        ? (value ? value.name : '')
        : (isOpened ? searchStr : (value.name || ''))

    const handleSearchInput = (event) => setSearchStr(event.target.value)

    return (
        <div className={selectClasses} ref={rootEl}>
            <input
                {...props}
                ref={inputEl}
                value={inputValue}
                onClick={toggleSelect}
                onChange={handleSearchInput}
                readOnly={isNotSearch}
                placeholder={placeholder}
                autoComplete='off'
                type='text'
                // onBlur={handleBlur}
            />

            <SelectArrow isOpened={isOpened} />

            {isOpened &&
                <div className={style.select_container}>
                    <SelectList options={filteredOptions} value={value} handleSelect={handleSelect} />
                </div>
            }
        </div>
    )
}