import style from './Label.module.css'

export const Label = ({ required, small, error, hover, children, ...props }) => {
    const labelClasses = style.label
        + (small ? ' ' + style.label_small : '')
        + (required ? ' ' + style.label_required : '')
        + (error ? ' ' + style.label_error : '')
        + (hover ? ' ' + style.label_hover : '')

    return (
        <label {...props} className={labelClasses}>
            {children}
        </label>
    )
}