import { useContext, useState } from 'react'
import { BurgerBtn, Button, Container, Logo, ProfileData } from 'common/components'
import style from './Header.module.css'
import { NavLink, useLocation } from 'react-router-dom'
import { AppContext } from 'index'
import { observer } from 'mobx-react-lite'
import { ddxMainLink, vkAnalitycs } from 'common/analytics'

export const Header = observer( ({ openForm }) => {
    const { pathname } = useLocation()
    const { authStore } = useContext(AppContext)
    
    const headerClasses = style.header + (pathname.startsWith('/admin') ? ' ' + style.header_admin : '')
    const headerContainerClasses = style.header_container + (pathname.startsWith('/admin') ? ' ' + style.header_container_admin : '')

    const [menu, setMenu] = useState(false)
    const toggleMenu = () => setMenu(prev => !prev)
    const closeMenu = () => setMenu(false)

    const isAdminRoute = pathname.startsWith('/admin')

    return (
        <header className={headerClasses}>

            {menu && !isAdminRoute && 
                <div className={style.menu} onClick={() => setMenu(false)}>
                    <div className={style.menu_links}>
                        <a className={style.menu_link} onClick={closeMenu} href='#contest'>О конкурсе</a>
                        <a className={style.menu_link} onClick={closeMenu} href='#included'>Что вас ждет</a>
                        <a className={style.menu_link} onClick={closeMenu} href='#family'>DDX - это семья</a>
                    </div>

                    <div className={style.menu_btn}>
                        <Button onClick={() => {closeMenu(); openForm()}}>Хочу&nbsp;участвовать</Button>
                    </div>
                    
                    <div className={style.menu_contacts}>
                        <a
                            id='uis-phone-to-replace'
                            onClick={() => vkAnalitycs('click_phone')}
                            href='tel:+74954891000'
                            className={style.phone_link}
                            target='_blank' rel='noreferrer'
                        >
                            + 7 (495) 489-10-00
                        </a>
                        <a href={ddxMainLink} className={style.web_link} rel='noreferrer' target='_blank'>ddxfitness.ru</a>
                    </div>
                </div>}

            <Container>
                <div className={headerContainerClasses}>

                    {isAdminRoute ? 
                        <NavLink to='/'>
                            <Logo />
                        </NavLink> :
                        
                        <a onClick={closeMenu} href='#start'>
                            <Logo />
                        </a>}
                    
                    {(isAdminRoute && authStore.isAuth) &&
                        <nav className={style.header_nav + ' ' + style.nav}>
                            <NavLink className={style.nav_link} to='/admin'>Заявки</NavLink>
                            <NavLink className={style.nav_link} to='/admin/analytics'>Аналитика</NavLink>
                        </nav>}
                        
                    {!isAdminRoute &&
                        <>
                            <nav className={style.header_nav + ' ' + style.nav}>
                                <a href='#contest' className={style.nav_link}>О конкурсе</a>
                                <a href='#included' className={style.nav_link}>Что вас ждет</a>
                                <a href='#family' className={style.nav_link}>DDX - это семья</a>
                            </nav>

                            <a
                                id='uis-phone-to-replace'
                                onClick={() => vkAnalitycs('click_phone')}
                                href='tel:+74954891000'
                                className={style.header_phone + ' ' + style.phone_link}
                                target='_blank' rel='noreferrer'
                            >
                                + 7 (495) 489-10-00
                            </a>
                            <BurgerBtn classes='header_btn' menu={menu} onClick={toggleMenu} />
                        </>}

                    {(isAdminRoute && authStore.isAuth) &&
                        <ProfileData name={authStore.user?.login} logout={() => authStore.logout()} />}
                </div>
            </Container>
        </header>
    )
} )