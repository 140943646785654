import style from './Container.module.css'

export const Container = ({ children, centered, ...props }) => {
    const containerClasses = style.container
        + (centered ? ' ' + style.container_centered : '')

    return (
        <div {...props} className={containerClasses}>
            {children}
        </div>
    )
}