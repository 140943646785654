import axios from 'axios'

const PRODUCTION = true

export const API_IMG = PRODUCTION 
    ? 'https://api.bigcity-ddx.ru/image/'
    : 'http://localhost:4000/image/'

export const API_DOMAIN = PRODUCTION 
    ? 'https://api.bigcity-ddx.ru/api'
    : 'http://localhost:4000/api'

export const API_STREAM = PRODUCTION
    ? 'https://api.bigcity-ddx.ru/stream'
    : 'http://localhost:4000/stream'

export const authApi = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_DOMAIN || API_DOMAIN
})

export const regApi = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN || API_DOMAIN
})

