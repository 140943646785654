import { useContext, useEffect, useRef } from 'react'
import { Header } from './layouts/Header/Header'
import { Footer } from 'layouts/Footer/Footer'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Main } from 'pages/Main/Main'
import { Admin } from 'pages/Admin/Admin'
import { Alert, Overlay, ViewImage } from 'common/components'
import { AppContext } from 'index'
import { observer } from 'mobx-react-lite'
import { ModalSuccess, ModalPromotionalOver, ModalForm, ModalTerms, ModalJoinUs, ModalSelectAge } from 'common/modals'
import { vkAnalitycs, yandexMetrics } from 'common/analytics'

export const App = observer( () => {
    const ref = useRef(null)
    const { appStore } = useContext(AppContext)
    const { pathname } = useLocation()
    const background = pathname.startsWith('/admin') ? { backgroundColor: '#F5F5FF'} : { }
    const appClasses = pathname.startsWith('/admin') ? 'app app_admin' : 'app'

    // open modal handlers
    const closeModal = () => appStore.setModal(null)
    const openSuccess = () => appStore.setModal(<ModalSuccess onClose={closeModal} />)
    const openPromOver = () => appStore.setModal(<ModalPromotionalOver onClose={closeModal} />)
    const openJoinUs = () => appStore.setModal(<ModalJoinUs onClose={closeModal} />)
    const openSelectAge = () => appStore.setModal(<ModalSelectAge onClose={closeModal} />)
    
    const openForm = () => {
        // yandex metrics
        yandexMetrics('view_form')
        vkAnalitycs('view_form')
        
        appStore.setModal(<ModalForm onClose={closeModal} onSuccess={openSuccess} />)
    }
    const openTerms = () => appStore.setModal(<ModalTerms onClose={closeModal} openForm={openForm} openJoinUs={openJoinUs} />)

    const openFormHandler = () => appStore.promotion ? openForm() : openPromOver()
    const openTermsHandler = () => appStore.promotion ? openTerms() : openPromOver()

    // close modal on ESCAPE keydown
    const handleEsc = (e) => e.key === 'Escape' ? closeModal() : null

    useEffect(() => {
        document.addEventListener('keydown', handleEsc)
        return () => document.removeEventListener('keydown', handleEsc)
    }, []) // eslint-disable-line

    useEffect(() => {
        ref.current.scrollIntoView({ behavior: 'smooth' })
    }, [appStore.scrollTop])

    return (
        <div ref={ref} className={appClasses} style={background}>
            {appStore.alert && <Alert message={appStore.alert} onClose={() => appStore.clearAlert()} />}
            
            {/* modals => terms, form, success, promotion_over */}
            {appStore.modal &&
                <Overlay>
                    {appStore.modal}
                </Overlay>
            }

            {/* leads popup gallery */}
            {appStore.gallery && 
                <Overlay>
                    <ViewImage images={appStore.gallery.images} initial={appStore.gallery.initial} />
                </Overlay>}
            
            <Header openForm={openFormHandler} />

            <Routes>
                <Route path='/' element={<Main
                                            openForm={openFormHandler}
                                            openTerms={openTermsHandler}
                                            openJoinUs={openJoinUs} />}
                                        />
                <Route path='/admin/*' element={<Admin
                                                openSelectAge={openSelectAge} />}
                                            />
                <Route path='*' element={<Navigate to='/' />} />
            </Routes>

            <Footer />
        </div>
    );
} )
