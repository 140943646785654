import { Button, Container } from 'common/components'
import style from './Contest.module.css'

export const Contest = ({ openTerms }) => {
    return (
        <section id='contest' className={style.contest}>
            <Container>
                <h2 className={style.contest_title}>Вы правильно поняли</h2>
                <p className={style.contest_text}>
                    <span>Конкурс для членов клуба, </span>
                    где победители получат профессиональную фотосессию в студии, а также возможность попасть в социальные медиа, наружную рекламу по всей России и на сайт DDX Fitness!
                </p>
                <div className={style.contest_footer}>
                    <p className={style.contest_dates}>
                        <span>С 20 по 27 октября</span> ждем ваши анкеты!
                    </p>
                    <Button onClick={openTerms}>Условия конкурса</Button>
                </div>
            </Container>
        </section>
    )
}
