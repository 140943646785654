import successIcon from './img/success-icon.svg'
import style from './SuccessIcon.module.css'

export const SuccessIcon = () => {
    return (
        <div className={style.success}>
            <img src={successIcon} alt='' />
        </div>
    )
}
