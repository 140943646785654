import style from './Table.module.css'
import { TableRow } from './TableRow'

export const Table = ({ mockup, data = [], showData = {}, onLike, onWatch }) => {

    // loading mockup
    if (mockup) {
        const rows = new Array(mockup).fill('')
        return (
            <div className={style.table}>
                {rows.map((_, idx) => {
                    return (
                        <TableRow
                            mockup
                            key={idx + Date.now}
                            showData={showData}
                        />)
                })}
            </div>
        )
    }

    // table data
    return (
        <div className={style.table}>
            {data.length ? data.map((row, idx) => {
                return (
                    <TableRow
                        key={idx + Date.now()}
                        rowData={row}
                        showData={showData}
                        onLike={onLike}
                        onWatch={onWatch}
                    />
                )
            }) : <div>Заявки не найдены.</div> }
        </div>
    )
}