import style from './Select.module.css'
import { SelectOption } from './SelectOption'

export const SelectList = ({ options, value, handleSelect }) => {
    return (
        <div className={style.options}>
            {options.map((option, idx) => {
                return <SelectOption
                    key={idx + Date.now()}
                    onClick={_ => handleSelect(option)}
                    active={value.id === option.id}
                >{option.name}</SelectOption>
            })}
            {!options.length && <SelectOption disabled>Ничего не найдено</SelectOption>}
        </div>
    )
}
