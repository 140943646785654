import { Button, Title, Block, Terms, Description, CustomLink } from 'common/components'

const TERMS = [
    'Заполните анкету.',
    'Прикрепите к анкете несколько фотографий: портрет, в полный рост, с разных ракурсов и с разными эмоциями, по возможности в движении. Если есть профессиональные снимки — отлично, присылайте! Если нет — ничего страшного, совсем скоро они у вас будут.',
    'Подтвердите своё согласие на обработку персональных данных и на использование фотографий на онлайн и оффлайн-площадках сети DDX Fitness в случае выигрыша.',
    'Дождитесь результатов конкурса.',
]

export const ModalTerms = ({ onClose, openForm, openJoinUs }) => {
    return (
        <Block close={onClose} large>
            <Title>Условия</Title>
            <Terms terms={TERMS} />
            <Description>
                * конкурс только для членов клуба DDX Fitness. Хочешь участвовать?<span> </span>
                <CustomLink onClick={openJoinUs}>Вступай в нашу большую семью!</CustomLink>
            </Description>
            <Button onClick={openForm}>Заполнить анкету</Button>
        </Block>
    )
}