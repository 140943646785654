import { Contest } from "./Contest/Contest"
import { Family } from "./Family/Family"
import { Hero } from "./Hero/Hero"
import { InCity } from "./InCity/InCity"
import { Included } from "./Included/Included"

export const Main = ({ openForm, openTerms, openJoinUs }) => {
    return (
        <main id='start'>
            <Hero openForm={openForm} openJoinUs={openJoinUs} />
            <Contest openTerms={openTerms} />
            <Included />
            <Family />
            <InCity openForm={openForm} />
        </main>
    )
}
