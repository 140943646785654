import { ProfileIcon } from './ProfileIcon'
import { ProfileName } from './ProfileName'
import style from './ProfileData.module.css'

export const ProfileData = ({ name, logout }) => {
    return (
        <div className={style.profile_data}>
            <div>
                <ProfileIcon />
                <ProfileName name={name} />
                <div>&nbsp;&nbsp;|</div>
                <button onClick={logout} className={style.profile_button}>выйти</button>
            </div>
        </div>
    )
}
