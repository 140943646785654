import { API_IMG } from 'common/http/config'
import { AppContext } from 'index'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { NavigationBtn } from './NavigationBtn'
import { ViewCloseBtn } from './ViewCloseBtn'
import style from './ViewImage.module.css'
import placeholderImage from './images/placeholder.png'

export const ViewImage = observer( ({ images, initial = 0 }) => {
    const { appStore } = useContext(AppContext)
    const [current, setCurrent] = useState(initial)
    const [loading, setLoading] = useState(true)
    const filteredImages = images.filter(i => i)

    // handling ARROW and ESCAPE buttons
    const handleKeydown = (e) => {
        if (e.key === 'Escape') {
            appStore.setGallery(null)
        }
        if (e.key === 'ArrowLeft') {
            handlePhotoChange(prev => (prev > 0) ? prev - 1 : prev)
        }
        if (e.key === 'ArrowRight') {
            handlePhotoChange(prev => (prev < (filteredImages.length - 1)) ? prev + 1 : prev)
        }
    }

    const handlePhotoChange = (index) => {
        setLoading(true)
        setCurrent(index)
    }

    const currentImageUrl = API_IMG + filteredImages[current]

    useEffect(() => {
        const img = new Image()
        img.src = currentImageUrl

        img.onload = () => {
            setLoading(false)
        }
    }, [currentImageUrl]) // eslint-disable-line

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown)
        return () => document.removeEventListener('keydown', handleKeydown)
    }, []) // eslint-disable-line
    
    return (
        <div className={style.view}>
            <ViewCloseBtn onClose={() => appStore.setGallery(null)} />
            <div className={style.view_container}>
                <NavigationBtn onClick={() => handlePhotoChange(prev => prev - 1)} prev disabled={current <= 0} />
                <div className={style.view_image}>

                    {loading ?
                        <>
                            <img key='loading' src={placeholderImage} alt={'Загрузка'} />
                            <div className={style.view_image_placeholder}></div>
                        </> :

                        <>
                            <img key={current} src={currentImageUrl} alt='' />
                            <a className={style.view_new_tab} href={currentImageUrl} target='_blank' rel='noreferrer'>
                                <span>Открыть в новой вкладке</span>
                            </a>
                        </>}
                </div>
                <NavigationBtn onClick={() => handlePhotoChange(prev => prev + 1)} disabled={current >= filteredImages.length - 1} />
            </div>
            <div className={style.view_dots}>
                {filteredImages.map((_, idx) => {
                    return (
                        <div key={idx} className={(idx === current) ? style.view_dot_active : ''} onClick={() => handlePhotoChange(idx)}></div>
                    )
                })}
            </div>
        </div>
    )
} )