import { authApi } from 'common/http'
import { makeAutoObservable } from 'mobx'

class AuthStore {

    isAuth = null
    user = null
    loading = false
    error = ''


    constructor() {
        makeAutoObservable(this)
    }


    ///////////////////////////////////////////////////
    // auth check //

    *auth() {
        this.loading = true
        const response = yield authApi.authCheck()

        if (response.status !== 200) {
            this.handleError(response.message)
            return
        }

        this.handleSuccess(response.data.user)
    }


    ///////////////////////////////////////////////////
    // login //

    *login(user) {
        this.loading = true
        const response = yield authApi.login(user)

        if (response.status !== 200) {
            this.handleError(response.message)
            return
        }

        this.handleSuccess(response.data.user)
    }


    ///////////////////////////////////////////////////
    // logout //

    *logout() {
        this.loading = true
        const response = yield authApi.logout()
        this.handleError(response.message)
    }

    
    ///////////////////////////////////////////////////
    // common handlers //

    // error handler //

    handleError(message) {
        this.isAuth = false
        this.user = null
        this.error = message
        this.loading = false
    }

    // response handler //

    handleSuccess(user) {
        this.isAuth = true
        this.user = user
        this.loading = false
        this.error = ''
    }

}

export default AuthStore