import { AppContext } from 'index'
import { useContext } from 'react'
import { Label } from 'common/components'
import style from './Gallery.module.css'
import { API_IMG } from 'common/http/config'

export const Gallery = ({ images = [] }) => {
    const { appStore } = useContext(AppContext)

    const preview = (image) => image.split('/').join('/preview/')

    return (
        <div className={style.gallery}>
            {!images.filter(i => i).length && 
                <Label>Нет фото.</Label> }
            {images.filter(i => i).map((image, idx) => {
                return (
                    <div
                        key={idx + Date.now()}
                        onClick={() => appStore.setGallery({ images, initial: idx })}
                        className={style.gallery_item}
                        style={{ backgroundColor: '#fff', backgroundImage: `url('${API_IMG+preview(image)}')` }}>
                    </div>
                )
            })}
        </div>
    )
}
