export const animationOffset = (pages, page) => {

    if (pages < 5) {
        return page
    }

    if (page === 1) {
        return 1
    }

    if (page === pages - 2) {
        return 3
    }

    if (page === pages - 1) {
        return 4
    }

    if (page === pages) {
        return 5
    }

    return 2
}