export const validatePhone = (val) => {
    const digits = val.replace(/[^\d]/g, '')
    return Boolean(digits.length === 11 
            && !digits.startsWith('77')
            && !digits.startsWith('70')
            && !digits.startsWith('71')
            && !digits.startsWith('72')
            && !digits.startsWith('76')
        )
}

export const isPromitionTime = () => {
    // 20 октября - 27 октября
    const start = new Date('2023-10-20T00:00:00')
    const end = new Date('2023-10-27T23:59:59')

    const now = new Date()

    const isInInverval = start <= now && now <= end
    const moscowDate = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Moscow' }))
    const isMoscowTimeZone = !isNaN(moscowDate.getTime())

    // console.log(start, end, now, isInInverval, moscowDate, isMoscowTimeZone)
    return isInInverval && isMoscowTimeZone
}