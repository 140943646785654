import galleryImage01 from './img/gallery-image-01.jpg'
import galleryImage02 from './img/gallery-image-02.jpg'
import galleryImage03 from './img/gallery-image-03.jpg'
import galleryImage04 from './img/gallery-image-04.jpg'
import galleryImage05 from './img/gallery-image-05.jpg'
import galleryImage06 from './img/gallery-image-06.jpg'
import galleryImage01Sm from './img/gallery-image-01-sm.jpg'
import galleryImage04Sm from './img/gallery-image-04-sm.jpg'

import style from './Family.module.css'
import { Container } from 'common/components'
import { GallerySlider } from './GallerySlider'
import { useEffect, useState } from 'react'

export const Family = () => {
    const [windowWidth, setWindowWidth] = useState(null)

    useEffect(() => {
        if (!windowWidth) {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize);
        
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth])

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    return (
        <section id='family' className={style.family}>
            <Container>
                <h2 className={style.family_title}>DDX - это семья!</h2>
                <p className={style.family_descr}>
                    <span>Иногда один маленький конкурс может изменить целую жизнь. </span>
                    Не упускайте момент — откройте себя миру!
                </p>
                {(windowWidth > 600) ?
                    <div className={style.family_gallery}>
                        <div className={style.family_image + ' ' + style.family_image_large}>
                            <img src={galleryImage01} alt='ddx фото' />
                        </div>
                        <div className={style.family_image}>
                            <img src={galleryImage02} alt='ddx фото' />
                        </div>
                        <div className={style.family_image}>
                            <img src={galleryImage03} alt='ddx фото' />
                        </div>
                        <div className={style.family_image + ' ' + style.family_image_large}>
                            <img src={galleryImage04} alt='ddx фото' />
                        </div>
                        <div className={style.family_image}>
                            <img src={galleryImage05} alt='ddx фото' />
                        </div>
                        <div className={style.family_image}>
                            <img src={galleryImage06} alt='ddx фото' />
                        </div>
                    </div>
                    :
                    <GallerySlider images={[galleryImage01Sm, galleryImage02, galleryImage03, galleryImage04Sm, galleryImage05, galleryImage06]} />
                }
            </Container>
        </section>
    )
}
