import { TableItem } from './TableItem'
import { convertNull } from './utils/convertNull'
import style from './Table.module.css'
import { Gallery } from '../Gallery/Gallery'
import { LikeBtn } from './btns/LikeBtn'
import { WatchedBtn } from './btns/WatchedBtn'
import { CopyData } from 'common/components'

export const TableRow = ({ mockup, rowData = {}, showData, onLike, onWatch }) => {
    if (mockup) {
        return (
            <div className={style.table_row}>
                {/* row data */ }
                <div className={style.table_row_data}>
                    {Object.keys(showData).map((item, idx) => {
                        return (
                            <TableItem key={idx + Date.now()}>
                                &nbsp;
                            </TableItem>
                        )
                    })}
                </div>

                {/* row images */ }
                <div className={style.table_row_images}>
                    <Gallery images={[]} />
                </div>
            </div>
        )
    }
    return (
        <div className={style.table_row}>

            {/* liked btn */}
            <div className={style.liked}>
                <LikeBtn onClick={() => onLike(rowData.id, !rowData.liked)} liked={rowData.liked} />
            </div>

            {/* watched btn */}
            <div className={style.watched}>
                <WatchedBtn onClick={() => onWatch(rowData.id, !rowData.watched)} watched={rowData.watched} />
            </div>

            {/* row data */}
            <div className={style.table_row_data}>
                {Object.keys(showData).map((item, idx) => {
                    return (
                        <TableItem key={idx + Date.now()}>
                            {showData[item] + ': '} <CopyData>{convertNull(rowData[item])}</CopyData>
                        </TableItem>
                    )
                })}
            </div>

            {/* row images */}
            <div className={style.table_row_images}>
                <Gallery images={[rowData.file_1, rowData.file_2, rowData.file_3]} />
            </div>
        </div>
    )
}
