import { Block, Button, ButtonBlock, Input, Label, Title } from 'common/components'
import { AppContext } from 'index'
import { useContext, useState } from 'react'

export const ModalSelectAge = ({ onClose }) => {
    const { leadStore } = useContext(AppContext)
    const [minAge, setMinAge] = useState(leadStore.minAge)
    const [maxAge, setMaxAge] = useState(leadStore.maxAge)

    const handleAgeFilter = () => {
        leadStore.setAgeFilter(minAge, maxAge)
        onClose()
    }

    const minAgeError = minAge < 18 || minAge > maxAge
    const maxAgeError = maxAge > 100 || maxAge < minAge

    const canSubmit = !(minAgeError || maxAgeError)

    const handleMinAgeChange = (event) => {
        const value = event.target.value
        value === ''
            ? setMinAge(value)
            : setMinAge(+value)
    }

    const handleMaxAgeChange = (event) => {
        const value = event.target.value
        value === ''
            ? setMaxAge(value)
            : setMaxAge(+value)
    }

    return (
        <Block close={onClose}>
            <Title small>Фильтр по возрасту</Title>

            <div style={{ display: 'flex', gap: 10, width: '100%' }}>
                <div style={{ flex: 1 }}>
                    <Label>От</Label>
                    <Input
                        value={minAge}
                        onChange={handleMinAgeChange}
                        // min={18} max={maxAge}
                        type='number'
                        error={minAgeError}
                    />
                </div>
                <div style={{ flex: 1 }}>
                    <Label>До</Label>
                    <Input
                        value={maxAge}
                        onChange={handleMaxAgeChange}
                        // min={minAge} max={100}
                        type='number'
                        error={maxAgeError}
                    />
                </div>
            </div>

            <ButtonBlock>
                <Button disabled={!canSubmit} onClick={handleAgeFilter}>Применить</Button>
                <Button light onClick={onClose}>Отмена</Button>
            </ButtonBlock>
        </Block>
    )
}