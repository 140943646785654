import { Container } from 'common/components'
import cameraIcon from './img/camera-icon.svg'
import playIcon from './img/play-icon.svg'
import galleryIcon from './img/gallery-icon.svg'
import buffetIcon from './img/buffet-icon.svg'
import style from './Included.module.css'

export const Included = () => {
    return (
        <section id='included' className={style.included}>
            <Container>
                <h2 className={style.included_title}>Что вас ждет:</h2>
                <div className={style.included_grid}>
                    <div className={style.included_item}>
                        <div className={style.included_img}>
                            <img src={cameraIcon} alt='Фотосессия' />
                        </div>
                        <p className={style.included_descr}><span>Фотосессия</span> с профессиональными стилистами и фотографами</p>
                    </div>

                    <div className={style.included_item}>
                        <div className={style.included_img}>
                            <img src={playIcon} alt='Съёмка' />
                        </div>
                        <p className={style.included_descr}><span>Съёмка</span> бэкстейджа от видеографа</p>
                    </div>

                    <div className={style.included_item}>
                        <div className={style.included_img}>
                            <img src={galleryIcon} alt='Фотоматериал' />
                        </div>
                        <p className={style.included_descr}><span>Качественный фотоматериал</span> для портфолио и социальных сетей</p>
                    </div>

                    <div className={style.included_item}>
                        <div className={style.included_img}>
                            <img src={buffetIcon} alt='Фуршет' />
                        </div>
                        <p className={style.included_descr}><span>Лёгкий</span> фуршет с закусками и напитками</p>
                    </div>
                </div>
                <p className={style.included_info}>Принять участие в конкурсе могут все члены сети клубов DDX Fitness, достигшие 18 лет любой комплекции.</p>
            </Container>
        </section>
    )
}