import style from './Select.module.css'

export const SelectOption = ({ children, active, disabled, ...props }) => {
    const optionClasses = style.option
        + (active ? ' ' + style.option_active : '')
        + (disabled ? ' ' + style.option_disabled : '')
        
    return (
        <div
            {...props}
            className={optionClasses}
        >
            {children}
        </div>
    )
}
