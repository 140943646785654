import style from './Checkbox.module.css'

export const Checkbox = ({ children, onChange, ...props }) => {
    const onClick = () => {
        onChange({ target: { value: !props.checked, name: props.name, id: props.id } })
    }

    return (
        <div className={style.checkbox}>
            <input {...props} readOnly />
            <svg onClick={onClick} type='checkbox' width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect className={style.animate_svg} x='0.5' y='0.5' width='19' height='19' rx='5.5' />
                <rect stroke='var(--grey-03)' x='0.5' y='0.5' width='19' height='19' rx='5.5'/>
                <path
                    className={style.animate_svg} 
                    strokeDashoffset={props.checked ? '0' : '9'}
                    strokeDasharray='9' d='M7 9.6L9.25 12L13 8'
                    stroke={props.checked ? 'var(--orange)' : '#fff'}
                    strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'
                />
            </svg>
            <label>{children}</label>
        </div>
    )
}
