import style from './Leads.module.css'

export const SortBtn = ({ asc = false, ...props }) => {
    const sortClasses = style.sort_btn
        + (asc ? ' ' + style.sort_btn_asc : '')

    return (
        <svg {...props} className={sortClasses} width='64px' height='64px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
            <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
            <g id='SVGRepo_iconCarrier'>
                <path d='M4 7H20' strokeWidth='2.4' strokeLinecap='round' strokeLinejoin='round'></path>
                <path d='M4 12L14 12' strokeWidth='2.4' strokeLinecap='round' strokeLinejoin='round'></path>
                <path d='M4 17H6' strokeWidth='2.4' strokeLinecap='round' strokeLinejoin='round'></path>
            </g>
        </svg>
    )
}
