import style from './Alert.module.css'
import { AlertCloseBtn } from './AlertCloseBtn'

export const Alert = ({ message, onClose }) => {
    return (
        <div className={style.alert}>
            <p>{message}</p>
            <AlertCloseBtn onClose={onClose} />
        </div>
    )
}
