import style from './Block.module.css'

export const BlockCloseBtn = ({ onClose }) => {
    return (
        <button className={style.block_close_btn} onClick={onClose}>
            <svg 
                width='12' height='12' viewBox='0 0 12 12' 
                fill='none' xmlns='http://www.w3.org/2000/svg'
            >
                <path 
                    d='M10.5 1.5L6 6L1.5 10.5M1.5 1.5L10.5 10.5' strokeWidth='2' 
                    strokeLinecap='round' strokeLinejoin='round'
                />
            </svg>
        </button>
    )
}
