import { isPromitionTime } from 'common/utils'
import { makeAutoObservable } from 'mobx'

class AppStore {

    scrollTop = false

    // promotion status
    isPromotion = false
    promotion = this.isPromotion || isPromitionTime()

    // gallery and modal
    gallery = null
    modal = null

    // alert
    alert = null
    alertTimeout = null

    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }

    *getPromotionStatus() {
        // fetch promo status
        // const response = yield
    }

    toggleScroll() {
        this.scrollTop = !this.scrollTop
    }

    setGallery(gallery) {
        this.gallery = gallery
    }

    setModal(modal) {
        this.modal = modal
    }

    setAlert(message, timeout = 5000) {
        if (this.alertTimeout) {
            clearTimeout(this.alertTimeout)
        }

        this.alert = message
        this.alertTimeout = setTimeout(() => {
            this.clearAlert()
        }, timeout)
    }

    clearAlert() {
        this.alert = null
    }

}

export default AppStore