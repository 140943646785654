import { useContext, useEffect } from 'react'
import { Container, Loader, Overlay } from 'common/components'
import { AppContext } from 'index'
import { Leads } from './Leads/Leads'
import { Login } from './Login/Login'
import { observer } from 'mobx-react-lite'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Analytics } from './Analytics/Analytics'

export const Admin = observer(({ openSelectAge }) => {
    const { authStore, leadStore } = useContext(AppContext)

    useEffect(() => {
        authStore.auth()
    }, []) // eslint-disable-line

    return (
        authStore.isAuth === null ? <div></div> :

        <div>

            {/* loader */}
            {(authStore.loading || leadStore.loading) &&
                <Overlay idx='100'>
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loader /></div>
                </Overlay>}

            <Container centered>

                {authStore.isAuth ?
                    <Routes>
                        <Route exact path='/' element={<Leads openSelectAge={openSelectAge} />} />
                        <Route exact path='/analytics' element={<Analytics />} />
                        <Route path='*' element={<Navigate to='/admin' />} />
                    </Routes> :

                    <Routes>
                        <Route exact path='/' element={<Login />} />
                        <Route path='*' element={<Navigate to='/admin' />} />
                    </Routes>}

            </Container>
            
        </div>
    )
})