import style from './FileInput.module.css'
import { UploadIcon } from './UploadIcon'

export const FileInput = ({ checkFiles, handleFileUpload, removeUploadingFile, files }) => {
    return (
        <div className={style.file_fieldset}>
            <label htmlFor='file'>
                <UploadIcon />
                
                <div>
                    <p>Прикрепить файл {'(' + files.length + '/3)'} <span>*</span></p>
                    <p className={style.size_descr}>Размер файла не более 8 Мб</p>
                </div>
                
            </label>
            <input
                accept='image/*'
                onClick={checkFiles}
                onChange={handleFileUpload}
                id='file' type='file'
            />
            {!!files.length && <div className={style.files}>
                {files.map((file, idx) => {
                    return (
                        <div key={idx + Date.now()} className={style.files_item + (file.sizeError ? ' ' + style.files_item_error : '')}>
                            <button onClick={() => removeUploadingFile(file.id)} className={style.files_btn}>
                                <svg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M10 1L1 10M1 1L10 10' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                                </svg>
                            </button>
                            <p className={style.files_name}>
                                {`${file.name.length > 10 ? file.name.slice(0, 12) + '...' + file.name.slice(file.name.length - 6, file.name.length) : file.name} ${file.sizeError ? ' (файл слишком большой)' : ''}`}</p>
                        </div>
                    )
                })}
            </div>}
        </div>
    )
}
