import { ddxTarrifsLink, vkAnalitycs, yandexMetrics } from 'common/analytics'
import { Block, Button, CustomLink, Description, Title } from 'common/components'

export const ModalJoinUs = ({ onClose }) => {
    
    // analytics
    const handleTariffClick = () => {
        yandexMetrics('click_tariff')
        vkAnalitycs('click_tariff')
    }

    const handlePhoneClick = () => {
        vkAnalitycs('click_phone')
    }

    return (
        <Block close={onClose}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                <Title medium>Вступай в нашу <span>большую&nbsp;семью!</span></Title>
                <Description>
                    Для того, чтобы стать членом клуба, переходите по ссылке ниже, либо звоните нам по номеру
                    <span> </span>
                    <CustomLink
                        id='uis-phone-to-replace'
                        onClick={handlePhoneClick}
                        style={{ whiteSpace: 'nowrap' }}
                        newTab
                        href='tel:+74954891000'
                    >
                        + 7 (495) 489-10-00
                    </CustomLink>
                </Description>
            </div>
            <a onClick={handleTariffClick} href={ddxTarrifsLink} target='_blank' rel='noreferrer'>
                <Button>Стать членом клуба</Button>
            </a>
        </Block>
    )
}