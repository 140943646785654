import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import style from './Analytics.module.css'
import { universities } from 'common/data'
import { AppContext } from 'index'
import { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'

Chart.defaults.font.size = 14
Chart.defaults.font.family = 'Montserrat'


const u_names = universities.map(u => u.shortName)

const calculateData = (leads, universities) => {
    const universityCounts = {}

    // Подсчитываем количество заявок по университетам
    leads.forEach(lead => {
        universityCounts[lead.university] = (universityCounts[lead.university] || 0) + 1
    })

    // Добавляем поле 'count'
    return universities.map(u => {
        return { ...u, count: universityCounts[u.name] || 0 }
    })
}

const findLongName = (universities = [], shortName = '') => {
    return universities.find(u => u.shortName === shortName)?.longName
}

export const Analytics = observer(() => {
    const { leadStore } = useContext(AppContext)

    useEffect(() => {
        leadStore.getLeads(true)
    }, [])

    const universitiesData = calculateData(leadStore.leads || [], universities).sort((a, b) => b.count - a.count)
    const data = {
        labels: u_names,
        datasets: [
            {
                backgroundColor: 'rgba(237, 105, 42, 1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(237, 105, 42, 0.8)',
                hoverBorderColor: 'rgba(237, 105, 42, 1)',
                data: universitiesData.map(u => u.count),
                borderRadius: 8,
            }
        ]
    }

    return (
        <div className={style.analytics}>
            <h1 className={style.analytics_title}>Аналитика 2.0</h1>

            <div className={style.analytics_grid}>
                <div style={{ height: '100%' }}>
                    <Bar
                        data={data}
                        plugins={[ChartDataLabels]}
                        options={{
                            maintainAspectRatio: false,
                            plugins: {
                                datalabels: {
                                    anchor: 'end',
                                    align: 'start',
                                    offset: -20,
                                    padding: 2,
                                    
                                },
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    callbacks: {
                                        title: (e) => findLongName(universitiesData, e[0]?.label),
                                    },
                                    backgroundColor: '#0CA1B1',
                                    padding: 5,
                                    titleFont: 'Montserrat'
                                }
                            },
                            scales: {
                                y: {
                                    ticks: {
                                        padding: 20,
                                        precision: 0,
                                        // callback: (val) => val + 1
                                    },
                                },
                            },
                        }}
                    />
                </div>

                <div>
                    <table border='1'>
                        <thead>
                            <tr>
                                <th>Наименование</th>
                                <th>Заявки</th>
                            </tr>
                        </thead>
                        <tbody>
                            {universities.map((u, index) => {
                                return (
                                    <tr key={index}>
                                        <td className={style.tooltip}>
                                            {u.shortName}
                                            <div className={style.tooltiptext}>{u.longName}</div>
                                        </td>
                                        <td>{universitiesData[index]?.count}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
})