import style from './Terms.module.css'

export const Terms = ({ terms = [] }) => {
    return (
        <ul className={style.terms}>
            {terms.map((t, idx) => {
                return (
                    <li key={idx + Date.now()} className={style.terms_item}>
                        <div className={style.terms_num}>{idx + 1}</div>
                        <p className={style.terms_text}>{t}</p>
                    </li>
                )
            })}
        </ul>
    )
}
