import { Button, Container } from 'common/components'
import ellipse from './img/ellipse.svg'
import style from './InCity.module.css'

export const InCity = ({ openForm }) => {
    return (
        <section className={style.in_city}>
            <Container>
                <div className={style.in_city_container}>
                    <h2 className={style.in_city_title}><span>DDX</span> В&nbsp;БОЛЬШОМ ГОРОДЕ</h2>
                    <div className={style.in_city_join_us}>
                        <div className={style.in_city_image}>
                            <img src={ellipse} alt='' />
                        </div>
                        <div className={style.in_city_btn}>
                            <Button onClick={openForm} light>Хочу&nbsp;участвовать</Button>
                        </div>
                    </div>
                    <p className={style.in_city_descr}>Заполняйте&nbsp;анкету&nbsp;и отправляйте&nbsp;заявку&nbsp;на участие&nbsp;в&nbsp;конкурсе. Ждем&nbsp;Вас!</p>
                </div>
            </Container>
        </section>
    )
}
