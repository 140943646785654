import style from './Title.module.css'

export const Title = ({ children, small, medium, ...props}) => {
    const titleClasses = style.title
        + (small ? ' ' + style.title_small : '')
        + (medium ? ' ' + style.title_medium : '')

    return (
        <h2 {...props} className={titleClasses}>
            {children}
        </h2>
    )
}