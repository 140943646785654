import style from './ViewImage.module.css'

export const NavigationBtn = ({ prev = false, disabled, onClick }) => {
    const btnClasses = style.navigation_btn 
        + (prev ? ' ' + style.navigation_btn_prev : '')
        + (disabled ? ' ' + style.navigation_btn_disabled : '')

    const handleClick = () => {
        if (!disabled) {
            onClick()
        }
    }

    return (
        <svg
            onClick={handleClick}
            className={btnClasses}
            height='200px' width='200px'
            version='1.1' id='XMLID_287_'
            xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'
        >
            <polygon points='6.8,23.7 5.4,22.3 15.7,12 5.4,1.7 6.8,0.3 18.5,12 '></polygon>
        </svg>
    )
}
