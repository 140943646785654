import { useEffect, useRef, useState } from 'react'
import style from './Family.module.css'

const AUTO_SLIDE = true
const AUTO_SLIDE_DELAY = 3000

export const GallerySlider = ({images}) => {
    const [current, setCurrent] = useState(0)
    const [touchPosition, setTouchPosition] = useState(null)
    const timeout = useRef()

    useEffect(() => {
        if (AUTO_SLIDE) {
            timeout.current = setTimeout(() => {
                changeSlide()
            }, AUTO_SLIDE_DELAY)
        }

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current)
            }
        } 
    }, [current]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeSlide = (direction = 1) => {
        let slideNumber = 0;

        if (current + direction < 0) {
            slideNumber = images.length - 1
        } else {
            slideNumber = (current + direction) % images.length
        }

        setCurrent(slideNumber)
        if (timeout.current) {
            clearTimeout(timeout.current)
        }
    };

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX;
        setTouchPosition(touchDown);
    }

    const handleTouchMove = (e) => {
        if (touchPosition === null) {
            return;
        }

        const currentPosition = e.touches[0].clientX;
        const direction = touchPosition - currentPosition;

        if (direction > 10) {
            changeSlide(1);
        }

        if (direction < -10) {
            changeSlide(-1);
        }
        setTouchPosition(null);
    }

    const sliderList = images.map( (img, idx) => {
        return (
            <div 
                key={idx}
                className={style.slider_image}
            >
                <img 
                    src={img} alt=''
                />
            </div>
        )
    })
    
    return (
        <>
            <div className={style.slider}>
                <div className={style.slider_left} onClick={() => changeSlide(-1)}></div>
                <div className={style.slider_right} onClick={() => changeSlide(1)}></div>
                <div className={style.slider_slide_list} style={{ transform: `translateX(-${current * 100}%)` }} onTouchStart={handleTouchStart} 
                    onTouchMove={handleTouchMove} 
                >
                    {sliderList}
                </div>
                <div className={style.slider_dots}>
                {images && images.map( (img, idx) => {
                        return <div key={idx} onClick={() => setCurrent(idx)} className={style.slider_dot + ((idx === current) ? ' ' + style.slider_dot_active : '')}></div>
                    })
                }                
                </div>
            </div>
        </>
    )
}
