import { Button, Container, CustomLink, Description, FlexColumn } from 'common/components'
// import heroImage from './img/header-bg.webp'
import heroImage from './img/header-bg.png'
import style from './Hero.module.css'

export const Hero = ({ openForm, openJoinUs }) => {

    return (
        <section className={style.hero}>
            <Container>
                <div className={style.hero_container}>
                    <div className={style.hero_left}>
                        <FlexColumn>
                            <h1 className={style.hero_title + ' ' + style.title}>
                                <span className={style.title_main}>DDX</span>
                                <span className={style.title_main}>В&nbsp;БОЛЬШОМ</span>
                                <span className={style.title_main}>ГОРОДЕ</span>
                                <span className={style.title_sub}>ищем&nbsp;новые лица</span>
                            </h1>
                            <div>
                                <Button onClick={openForm}>Хочу&nbsp;участвовать</Button>
                            </div>
                        </FlexColumn>
                        <Description style={{ marginTop: 'auto' }}>
                            Конкурс только для членов клуба DDX Fitness. Хочешь участвовать?<span> </span>
                            <CustomLink onClick={openJoinUs}>Вступай в нашу большую семью!</CustomLink>
                        </Description>
                    </div>
                    <div className={style.hero_right}>
                        <div className={style.hero_image}>
                            <img src={heroImage} alt='DDX В БОЛЬШОМ ГОРОДЕ' />
                            <div className={style.hero_image_circle}></div>
                            <div className={style.hero_image_circle}></div>
                            <div className={style.hero_image_circle}></div>
                            <div className={style.hero_image_circle}></div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}