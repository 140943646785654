import style from './Table.module.css'

export const TableItem = ({ children, large }) => {
    const itemClasses = style.table_item
        + (large ? ' ' + style.table_item_large : '')

    return (
        <div className={itemClasses}>
            <p>{children}</p>
        </div>
    )
}
