import { Form, Block, Label, Input, Button, Title, Fieldset, FlexColumn, FormGrid } from 'common/components'
import React, { useContext } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { observer } from 'mobx-react-lite'
import { AppContext } from 'index'

export const Login = observer( () => {
    const { authStore } = useContext(AppContext)

    const formik = useFormik({
        initialValues: { login: '', password: '' },
        validationSchema: Yup.object({
            login: Yup.string().trim().required().min(4).max(20),
            password: Yup.string().trim().required().min(6).max(20),
        }),
        onSubmit: async (values, actions) => {
            const user = { login: values.login, password: values.password }
            authStore.login(user)
            actions.resetForm()
        },
    })
    return (
        <Block shadowed w400>
            <Form onSubmit={formik.handleSubmit}>

                <FlexColumn>
                
                <Title medium>
                    Авторизация
                    {(authStore.error !== 'Не авторизован') && 
                        <Label error>{authStore.error}</Label>}
                </Title>

                <FormGrid oneRow>

                <Fieldset>
                    <Label required>Логин</Label>
                    <Input
                        error={formik.errors.login && formik.touched.login}
                        type='text' placeholder='Введите логин' name='login' id='login'
                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.login} //disabled={loading}
                    />
                </Fieldset>

                <Fieldset>
                    <Label required>Пароль</Label>
                    <Input
                        error={formik.errors.password && formik.touched.password}
                        type='password' placeholder='Введите пароль' name='password' id='password'
                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                        value={formik.values.password} //disabled={loading}
                    />
                </Fieldset>

                </FormGrid>

                <Button type='submit'>Войти</Button>
                </FlexColumn>
            </Form>
        </Block>
    )
} )
