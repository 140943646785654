import style from './Block.module.css'
import { BlockCloseBtn } from './BlockCloseBtn'

export const Block = ({ children, large, close, colored, shadowed, w400, ...props }) => {
    const blockClasses = style.block
        + (w400 ? ' ' + style.block_w400 : '')
        + (large ? ' ' + style.block_large : '')
        + (colored ? ' ' + style.block_colored : '')
        + (shadowed ? ' ' + style.block_shadowed : '')

    return (
        <div {...props} className={blockClasses}>
            {close && <BlockCloseBtn onClose={close} />}
            {children}
        </div>
    )
}