import style from './FormGrid.module.css'

export const FormGrid = ({ children, oneRow }) => {
    const componentClasses = style.form_grid
        + (oneRow ? ' ' + style.form_grid_one_row : '')

    return (
        <div className={componentClasses}>
            {children}
        </div>
    )
}
