import { authApi, regApi } from './config'

export const postLead = async(data) => {
    try {
        const response = await regApi.post('/lead', data)
        return response
    } catch (err) {
        return err.response
    }
}

export const getLeads = async(page = 1, limit = 10, sort, status = 0, season = 0, minAge = 0, maxAge = 150) => {
    try {
        const filterStr = '?page=' + page
            + '&limit=' + limit
            + (sort ? '&sort=' + sort : '')
            + '&status=' + status
            + '&season=' + season
            + '&minAge=' + minAge
            + '&maxAge=' + maxAge
            
        const response = await authApi.get('/lead' + filterStr)
        return response
    } catch (err) {
        return err.response
    }
}

export const getCount = async(season = 0, minAge = 0, maxAge = 150) => {
    try {
        const filterStr = 'season=' + season
            + '&minAge=' + minAge
            + '&maxAge=' + maxAge
        const response = await authApi.get('/lead/count?' + filterStr)
        return response
    } catch (err) {
        return err.response
    }
}

export const setWatched = async(id, watched) => {
    try {
        const response = await authApi.put('/lead/watched/' + id, { watched })
        return response
    } catch (err) {
        return err.response
    }
}

export const setLiked = async(id, liked) => {
    try {
        const response = await authApi.put('/lead/liked/' + id, { liked })
        return response
    } catch (err) {
        return err.response
    }
}