import { ddxMainLink, vkAnalitycs } from 'common/analytics'
import { Button, Container, Logo, SocialIcon } from 'common/components'
import style from './Footer.module.css'

export const Footer = () => {
    return (
        <footer className={style.footer}>
            <Container>
                <div className={style.footer_container}>

                    <div className={style.footer_row}>
                        <a href='#start'>
                            <Logo />
                        </a>

                        <a
                            onClick={() => vkAnalitycs('click_phone')}
                            href={ddxMainLink}
                            className={style.footer_phone_link}
                            target='_blank' rel='noreferrer'
                        >
                            <p>ddxfitness.ru</p>
                            <svg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M1.5 9L9.5 1M9.5 1H1.5M9.5 1V9' strokeWidth='2' strokeLinecap='round' />
                            </svg>
                        </a>

                        <div className={style.footer_social_links}>
                            <a href="https://vk.com/ddxfitness" rel="noreferrer" target="_blank">
                                <Button social><SocialIcon icon='vk' /></Button>
                            </a>
                            <a href="https://t.me/ddxfitness_official" rel="noreferrer" target="_blank">
                                <Button social><SocialIcon icon='tg' /></Button>
                            </a>
                            <a href="https://www.youtube.com/channel/UCF8MClm91HIe3k2ieXX3Scg" rel="noreferrer" target="_blank">
                                <Button social><SocialIcon icon='yt' /></Button>
                            </a>
                            <a href="https://dzen.ru/ddx_fitness_ru" rel="noreferrer" target="_blank">
                                <Button social><SocialIcon icon='dzen' /></Button>
                            </a>
                        </div>
                        {/* <a href={ddxMainLink} rel='noreferrer' className={style.web_link} target='_blank'>ddxfitness.ru</a> */}
                    </div>

                    <div className={style.footer_divider}></div>

                    <div className={style.footer_row}>
                        <p className={`${style.web_text} ${style.footer_rights}`}>© 2024 ООО «ИЛОН» | Ilon, LLC</p>
                        <a
                            href='https://www.ddxfitness.ru/policy/'
                            target='_blank'
                            rel='noreferrer'
                            className={style.web_link}
                        >
                            Соглашение о защите персональных данных
                        </a>
                        <a
                            href='https://www.ddxfitness.ru/terms_of_use/'
                            target='_blank'
                            rel='noreferrer'
                            className={style.web_link}
                        >
                            Пользовательское соглашение
                        </a>
                    </div>
                </div>
            </Container>
        </footer>
    )
}