import { Block, Button, Description, SuccessIcon, Title } from 'common/components'

export const ModalSuccess = ({ onClose }) => {
    return (
        <Block close={onClose}>
            <SuccessIcon />
            <Title medium>Поздравляем!</Title>
            <Description>Ваша заявка на участие в конкурсе успешно принята. Увидимся в DDX Fitness!</Description>
            <Button onClick={onClose}>Вернуться на главную</Button>
        </Block>
    )
}