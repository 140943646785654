    import { Block, Label } from 'common/components'
import { AppContext } from 'index'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import style from './Leads.module.css'
import { SortBtn } from './SortBtn'

const STATUS_FILTER_LIST = [
    { id: 0, name: 'Все', value: 'all' },
    { id: 1, name: 'Новые', value: 'new' },
    { id: 2, name: 'Просмотренные', value: 'watched' },
    { id: 3, name: 'Избранные', value: 'liked'},
]

export const LeadsSort = observer( ({ sort, setSort, limit, setLimit, status, setStatus, openSelectAge }) => {
    const { leadStore } = useContext(AppContext)
    const active = {textDecoration: 'underline', fontWeight: 600}    

    const handleAgeFilterReset = () => leadStore.resetAgeFilter()
    const isDefaultAge = leadStore.isDefaultAge
    const displayedAgeFilter = isDefaultAge
        ? 'не указан'
        : `от ${leadStore.minAge} до ${leadStore.maxAge}`

    return (
        <Block shadowed style={{ padding: 20, paddingRight: 5, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', maxWidth: 'none'}}>
            <div className={style.sort_item}>
                <Label>Сортировка: </Label>
                <SortBtn asc={sort} onClick={() => setSort(!sort)} />
            </div>

            <div className={style.sort_item}>
                <Label >Возраст: </Label>
                <Label onClick={openSelectAge} hover>{displayedAgeFilter}</Label>
                {!isDefaultAge &&
                    <button onClick={handleAgeFilterReset} style={{ lineHeight: 0 }}>
                        <svg width="14px" height="14px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Close_MD"> <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="var(--grey-01)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g> </g></svg>    
                    </button>}        
            </div>


            {/* Все, Просмотрено, Новые, Избранные */}

            <div style={{display: 'flex', gap: 10}}>
                {STATUS_FILTER_LIST.map((item) => {
                    return (
                        <Label
                            key={item.id}
                            style={(item.id === status) ? active : {}}
                            onClick={() => setStatus(item.id)}
                            hover
                        >
                                {item.name} ({leadStore.count[item.value]})
                        </Label>
                    )
                })}
            </div>

            <div className={style.sort_item}>
                <Label>Заявок на странице: </Label>
                <input className={style.sort_input}
                    type='number' value={limit}
                    onChange={(e) => setLimit(e.target.value)}
                    onKeyDown={(e) => {e.preventDefault()}}
                    step={10} min={10} max={50}
                />
            </div>
        </Block>
    )
} )
