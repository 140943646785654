import { getCount } from './lead.api'
import { getLeads } from './lead.api'
import { postLead } from './lead.api'
import { setWatched } from './lead.api'
import { setLiked } from './lead.api'

import { authCheck } from './auth.api'
import { login } from './auth.api'
import { logout } from './auth.api'

export const leadApi = {
    getCount,
    getLeads,
    postLead,
    setWatched,
    setLiked,
}

export const authApi = {
    authCheck,
    login,
    logout,
}
