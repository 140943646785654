export const visiblePages = (pages, currentPage) => {
    // creating array of page numbers
    const pageList = new Array(pages).fill('').map((_, idx) => idx + 1)

    if (pages <= 5) {
        return pageList
    }

    if (currentPage <= 1 + 1) {
        return [...pageList.slice(0, 3), null, pages]

    }

    if (currentPage >= pages - 2) {
        return [1, null, ...pageList.slice(pages - 3)]
    }

    return [1, ...pageList.slice(currentPage - 1, currentPage + 1), null, pages]
}