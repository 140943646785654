const clubs = [
    { id: 26, name: "DDX Авиапарк", },
    { id: 5, name: "DDX Беляево", },
    { id: 31, name: "DDX Бирюлево Бирюсинка", },
    { id: 18, name: "DDX Бибирево Будапешт", },
    { id: 24, name: "DDX Братеево Браво", },
    { id: 1, name: "DDX Бутово", },
    { id: 17, name: "DDX Варшавская Ангара", },
    { id: 3, name: "DDX Водный Стадион", },
    { id: 39, name: "DDX Воронеж" },
    { id: 21, name: "DDX Домодедово", },
    { id: 9, name: "DDX Жулебино", },
    { id: 11, name: "DDX Зеленоград Электрон", },
    { id: 28, name: "DDX Казань Пушкина", },
    { id: 29, name: "DDX Коломенская Орбита", },
    { id: 13, name: "DDX Коньково", },
    { id: 2, name: "DDX Красногорск", },
    { id: 20, name: "DDX Марьино Экран", },
    { id: 32, name: "DDX Митино", },
    { id: 33, name: "DDX Мозаика", },
    { id: 12, name: "DDX Москворечье", },
    { id: 6, name: "DDX Мытищи Июнь", },
    { id: 10, name: "DDX Мытищи Красный Кит", },
    { id: 34, name: "DDX Некрасовка Атлант", },
    { id: 23, name: "DDX Новогиреево Киргизия", },
    { id: 30, name: "DDX Новокузнецкая", },
    { id: 7, name: "DDX Одинцово", },
    { id: 35, name: "DDX Озерная", },
    { id: 16, name: "DDX Отрадное Байконур", },
    { id: 38, name: "DDX Полежаевская", },
    { id: 37, name: "DDX Преображенское Янтарь", },
    { id: 27, name: "DDX Ростов-на-Дону", },
    { id: 8, name: "DDX Ростокино", },
    { id: 4, name: "DDX Саларис", },
    { id: 22, name: "DDX Северное Сияние", },
    { id: 14, name: "DDX София", },
    { id: 19, name: "DDX Химки", },
    { id: 15, name: "DDX Щука", },
    { id: 36, name: "DDX Ярославль Аура", },
    { id: 25, name: "DDX Ясенево",  },
]

const universities = [
    { id: 1, name: 'ВШЭ (Высшая школа экономики)', shortName: 'ВШЭ', longName: 'Высшая школа экономики' },
    { id: 2, name: 'МГТУ (Московский государственный технический университет имени Н. Э. Баумана)', shortName: 'МГТУ', longName: 'Московский государственный технический университет имени Н. Э. Баумана' },
    { id: 3, name: 'МГСУ (Московский государственный строительный университет)', shortName: 'МГСУ', longName: 'Московский государственный строительный университет' },
    { id: 4, name: 'РАНХиГС (Российская академия народного хозяйства и государственной службы)', shortName: 'РАНХиГС', longName: 'Российская академия народного хозяйства и государственной службы' },
    { id: 5, name: 'МГУ (Московского государственного университета имени М.В.Ломоносова)', shortName: 'МГУ', longName: 'Московского государственного университета имени М.В.Ломоносова' },
    { id: 6, name: 'МИСиС (Университет науки и технологий)', shortName: 'МИСиС', longName: 'Университет науки и технологий' },
    { id: 7, name: 'РЭУ (Российский экономический университет имени Г. В. Плеханова)', shortName: 'РЭУ', longName: 'Российский экономический университет имени Г. В. Плеханова' },
    { id: 8, name: 'РГАУ-МСХА им. К.А. Тимирязева (Российский государственный аграрный университет - МСХА имени К.А. Тимирязева)', shortName: 'РГАУ-МСХА', longName: 'Российский государственный аграрный университет - МСХА имени К.А. Тимирязева)' },
    { id: 9, name: 'РХТУ (Российский химико-технологический университет имени Д.И. Менделеева)', shortName: 'РХТУ', longName: 'Российский химико-технологический университет имени Д.И. Менделеева' },
    { id: 10, name: 'МПГУ (Московский педагогический государственный университет)', shortName: 'МПГУ', longName: 'Московский педагогический государственный университет' },
    { id: 11, name: 'РГСУ (Российский государственный социальный университет)', shortName: 'РГСУ', longName: 'Российский государственный социальный университет' },
    { id: 12, name: 'МИРЭА (Российский технологический университет)', shortName: 'МИРЭА', longName: 'Российский технологический университет' },
    { id: 13, name: 'МГМУ (Московский государственный медицинский университет имени И.М. Сеченова)', shortName: 'МГМУ', longName: 'Московский государственный медицинский университет имени И.М. Сеченова' },
    { id: 14, name: 'МГАХИ (Московский государственный академический художественный институт имени В. И. Сурикова)', shortName: 'МГАХИ', longName: 'Московский государственный академический художественный институт имени В. И. Сурикова' },
    { id: 15, name: 'МАРХИ (Московский архитектурный институт)', shortName: 'МАРХИ', longName: 'Московский архитектурный институт' },
    { id: 16, name: 'РУДН (Российский университет дружбы народов)', shortName: 'РУДН', longName: 'Российский университет дружбы народов' },
    { id: 17, name: 'МГЮА (Московский государственный юридический университет имени О. Е. Кутафина)', shortName: 'МГЮА', longName: 'Московский государственный юридический университет имени О. Е. Кутафина' },
    { id: 18, name: 'МГЛУ (Московский государственный лингвистический университет)', shortName: 'МГЛУ', longName: 'Московский государственный лингвистический университет' },
]

const typesOfShooting = [
    { id: 1, name: 'Дневная в фотостудии' },
    // { id: 2, name: 'Ночная в клубе DDX Fitness' },
]

const emailREGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line

export {
    clubs,
    universities,
    typesOfShooting,
    emailREGEX,
}