import style from '../Table.module.css'

export const LikeBtn = ({ liked, ...props }) => {
    const likeStyles = style.like_btn
        + (liked ? ' ' + style.like_btn_liked : '')
        
    return (
        <div {...props} className={likeStyles}>
            <svg width='19' height='18' viewBox='0 0 24 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M22.9377 3.26728C21.8138 1.37769 20.0344 0.194671 18.0561 0.021817C16.1464 -0.144993 14.2765 0.650699 12.9392 2.20454L12.0197 3.27276L11.3521 2.49733C9.01254 -0.221793 5.3274 -0.728645 2.78417 1.31237C1.21184 2.57521 0.236304 4.46789 0.0367917 6.64249C-0.169138 8.88087 0.496148 11.0749 1.86309 12.6627L3.27463 14.2977L11.4654 22.3709C11.6207 22.5229 11.8196 22.5992 12.0191 22.5992C12.2186 22.5992 12.4181 22.5229 12.5739 22.3697L20.7591 14.303C20.7749 14.2882 20.7957 14.2664 20.8103 14.2491L21.9233 12.9559C24.2465 10.2576 24.6726 6.18275 22.9374 3.2678L22.9377 3.26728Z' />
            </svg>
        </div>
    )
}
