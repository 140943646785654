import style from './BurgerBtn.module.css'

export const BurgerBtn = ({ menu = false, onClick }) => {
    const burgerClasses = style.burger
        + (menu ? ' ' + style.burger_active : '')

    return (
        <button onClick={onClick} className={burgerClasses}>
            <svg fill='none' viewBox="-10 -10 120 120">
                <path
                    strokeWidth='9' 
                    strokeLinecap='round' 
                    strokeLinejoin='round'
                    d='m 20 40 h 60 a 1 1 0 0 1 0 20 h -60 a 1 1 0 0 1 0 -40 h 30 v 70'>
                </path>
            </svg>
        </button>
    )
}
